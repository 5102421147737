@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .tab_span {
        @apply font-semibold hover:bg-default-gray px-4 py-2 rounded-md duration-300 cursor-pointer
    }
}

@layer components {
  .new_tab_span {
      @apply font-semibold hover:bg-default-gray px-7 py-3 rounded-md duration-300 cursor-pointer
  }
}

@layer components {
    .button_effect {
        @apply hover:opacity-80 active:opacity-80 duration-300
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 2px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(109, 106, 106); 
}

.toolBar-btn{
  @apply flex items-center border-gray-700 border-l  px-2 hover:bg-gray-900 hover:text-white transition-all duration-300;
}

.editor-base{
 @apply prose prose-headings:m-0 prose-p:m-0  focus:outline-none max-w-none border border-gray-700 p-2 rounded; 
}